import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import InformationGrid from './InformationGrid';
import TabCard from './TabCard';
import Card from './Card';
import ContactPersonSelectModal from './ContactPersonSelectModal';
import Button from './Button';
import DeleteConfirmation from './DeleteConfirmation';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { CustomerBM, PersonBM } from '@bm-js/h2o-shared';

const StyledCustomerTabCard = styled.div`
  .add-contact-person-button,
  .delete-contact-person-button {
    display: flex;
    justify-content: flex-end;

    button {
      margin-top: 0.5rem;
    }
  }
`;

type Props = {
  customer: CustomerBM;
  refetchCustomer: () => Promise<void>;
};

const CustomerTabCard = ({ customer, refetchCustomer }: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [activeTab, setActiveTab] = useState(0);
  const [contactPersonModalActive, setContactPersonModalActive] =
    useState(false);
  const [pendingDeletePerson, setPendingDeletePerson] = useState<{
    name: string;
    title: string;
    phone: string;
    mobile: string;
    email: string;
  } | null>(null);

  const {
    customerInformation,
    invoiceInformation,
    deliveryInformation,
    visitorInformation,
    contactPersons,
  } = customer;

  const parsePhoneNumber = (input: string) =>
    input.split(' ').join('').split('-').join('');

  const addExistingContactPerson = async (person: PersonBM) => {
    await request({
      state,
      dispatch,
      path: 'persons',
      method: 'PUT',
      body: {
        customerId: customer._id,
        personId: person._id,
      },
    });
    setContactPersonModalActive(false);
    refetchCustomer();
  };

  const deleteContactPersonInit = (person: {
    name: string;
    title: string;
    phone: string;
    mobile: string;
    email: string;
  }) => {
    setPendingDeletePerson(person);
  };

  const deleteContactPerson = async (person: {
    name: string;
    title: string;
    phone: string;
    mobile: string;
    email: string;
    _id?: string;
  }) => {
    await request({
      state,
      dispatch,
      path: `persons/${customer._id}/${person._id}`,
      method: 'DELETE',
    });
    refetchCustomer();
    setPendingDeletePerson(null);
  };

  return (
    <StyledCustomerTabCard>
      <TabCard
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          'Kunduppg.',
          'Fakturauppg.',
          'Leveransadr.',
          'Besöksadr.',
          'Kontaktpers.',
        ]}
        contents={[
          <InformationGrid
            pairs={[
              { h: 'Namn', p: customerInformation?.name },
              { h: 'Län', p: customerInformation?.county },
              { h: 'Postadress', p: customerInformation?.postalAdress },
              { h: 'Postnummer', p: customerInformation?.zipCode },
              { h: 'Postort', p: customerInformation?.postalArea },
              { h: 'Telefon', p: customerInformation?.phone },
            ]}
          />,
          <InformationGrid
            pairs={[
              { h: 'Namn', p: invoiceInformation?.name },
              { h: 'Postadress', p: invoiceInformation?.postalAdress },
              { h: 'Postnummer', p: invoiceInformation?.zipCode },
              { h: 'Postort', p: invoiceInformation?.postalArea },
              {
                h: 'Märkningar',
                p: invoiceInformation?.markings.map((line, i) => (
                  <span key={`markings_${line}_${i}`}>
                    {line}
                    <br />
                  </span>
                )),
              },
              {
                h: 'E-post',
                p: invoiceInformation?.email,
                href: `mailto:${invoiceInformation?.email}`,
              },
              { h: 'Faktureringsmetod', p: invoiceInformation.invoiceMethod },
              invoiceInformation.invoiceMethod === 'E-faktura'
                ? {
                    h: 'Uppgifter E-fakturering',
                    p: invoiceInformation.eInvoiceInfo.map((line, i) => (
                      <span key={`eInvoiceInfo_${line}_${i}`}>
                        {line}
                        <br />
                      </span>
                    )),
                  }
                : null,
              {
                h: 'Anbudsnummer',
                p: invoiceInformation.anbudNumbers.map((line, i) => (
                  <span key={`anbudsNr_${line}_${i}`}>
                    {line}
                    <br />
                  </span>
                )),
              },
            ]}
          />,
          <InformationGrid
            pairs={[
              { h: 'Namn', p: deliveryInformation?.name },
              { h: 'Postadress', p: deliveryInformation?.postalAdress },
              { h: 'Postnummer', p: deliveryInformation?.zipCode },
              { h: 'Postort', p: deliveryInformation?.postalArea },
            ]}
          />,
          <InformationGrid
            pairs={[
              { h: 'Namn', p: visitorInformation?.name },
              { h: 'Postadress', p: visitorInformation?.postalAdress },
              { h: 'Postnummer', p: visitorInformation?.zipCode },
              { h: 'Postort', p: visitorInformation?.postalArea },
            ]}
          />,
          <div>
            {contactPersons?.map((person) => (
              <Card inner key={person._id}>
                <InformationGrid
                  inner
                  pairs={[
                    { h: 'Namn', p: person.name },
                    { h: 'Titel', p: person.title },
                    {
                      h: 'Telefon',
                      p: person.phone,
                      href: `tel:${parsePhoneNumber(person.phone)}`,
                    },
                    { h: 'Mobil', p: person.mobile },
                    {
                      h: 'E-post',
                      p: person.email,
                      href: `mailto:${person.email}`,
                      fw: true,
                    },
                  ]}
                />
                <div className="delete-contact-person-button">
                  <Button
                    small
                    look="danger"
                    onClick={() => deleteContactPersonInit(person)}
                  >
                    Ta bort
                  </Button>
                </div>
              </Card>
            ))}
            <div className="add-contact-person-button">
              <Button small onClick={() => setContactPersonModalActive(true)}>
                Lägg till befintlig kontaktperson
              </Button>
            </div>
          </div>,
        ]}
      />
      {contactPersonModalActive && (
        <ContactPersonSelectModal
          onClose={() => setContactPersonModalActive(false)}
          onSelect={addExistingContactPerson}
        />
      )}
      {pendingDeletePerson && (
        <DeleteConfirmation
          close={() => setPendingDeletePerson(null)}
          commit={deleteContactPerson}
          item={pendingDeletePerson}
          itemIdentifier={'kontaktperson'}
          copyOverride={
            <p>
              Är du säker på att du vil ta bort{' '}
              <strong>{pendingDeletePerson.name} </strong>
              från kunden?
            </p>
          }
        />
      )}
    </StyledCustomerTabCard>
  );
};

export default CustomerTabCard;
